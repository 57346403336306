<template>
  <el-card>
    <div class="title">Total income</div>
    <el-row class="search">
      <el-select v-model="type" placeholder="" filterable>
        <el-option label="Day" value="1"></el-option>
        <el-option label="Month" value="2"></el-option>
      </el-select>
      <el-date-picker v-model="search.date" type="daterange" align="right" value-format="yyyy-MM-dd" unlink-panels
        placeholder="Date" :picker-options="pickerOptions" v-if="type == 1">
      </el-date-picker>
      <el-date-picker v-model="search.date1" type="month" align="right" unlink-panels placeholder="Period"
        :picker-options="pickerOptions1" v-else>
      </el-date-picker>
      <el-button type="primary" @click="query()" :loading="loading">Search</el-button>
    </el-row>
    <el-row>
      <el-table :data="list" style="width: 100%" v-loading="loading" stripe row-key="agentId" border default-expand-all
        :tree-props="{ children: 'sonTotals', hasChildren: 'hasChildren' }" element-loading-text="loading"
        element-loading-spinner="el-icon-loading">
        <el-table-column width="220px" prop="period" label="Date"></el-table-column>
        <el-table-column prop="activeHostess" label="Active hostess"></el-table-column>
        <el-table-column prop="totalIncome" label="Total diamond income">
          <template slot-scope="scope">
            {{ $store.getters.getCurDiamondNum(scope.row.totalIncome) }}
          </template>
        </el-table-column>
        <el-table-column prop="estimateCommission" label="Estimate commission（USD）"></el-table-column>
      </el-table>
    </el-row>
  </el-card>
</template>

<script>
import { getTotalIncome } from '@/api/report.js'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      loading: false,
      list: [],
      type: '1',
      search: {
        date: [
          dayjs(new Date()).format('YYYY-MM-DD'),
          dayjs(new Date()).format('YYYY-MM-DD')
        ],
        date1: dayjs()
          .add(-1, 'month')
          .startOf('month')
          .format('YYYY-MM')
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now()
        }
      },
      pickerOptions1: {
        disabledDate(time) {
          return dayjs(time).month() >= dayjs(new Date()).month()
        }
      }
    }
  },
  created() {
    this.query()
  },
  methods: {
    // 获取列表
    async query() {
      let day = ['', '']
      if (this.type == 1) {
        day[0] = this.search.date[0]
        day[1] = this.search.date[1]
      } else {
        day[0] = dayjs(this.search.date1)
          .startOf('month')
          .format('YYYY-MM-DD')
        day[1] = dayjs(this.search.date1)
          .endOf('month')
          .format('YYYY-MM-DD')
      }
      const { data: res } = await getTotalIncome({
        start: day[0],
        end: day[1],
        agentId: JSON.parse(sessionStorage.getItem('loginData')).agentId,
        queryType: this.type == 1 ? 1 : 0
      })
      if (res.code !== 0)
        return this.$message({
          message: res.msg,
          center: true,
          type: 'error'
        })
      this.$message({
        message: 'success',
        center: true,
        type: 'success'
      })
      this.list = res.data
    }
  }
}
</script>
